import * as React from 'react';
import {Children} from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {FormWithRedirect} from 'ra-core';
import { FormInput } from 'react-admin';
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Toolbar from './Toolbar';

/**
 * Form with a one column layout, one input per line.
 *
 * Pass input components as children.
 *
 * @example
 *
 * import * as React from "react";
 * import { Create, Edit, ModalForm, TextInput, DateInput, ReferenceManyField, Datagrid, TextField, DateField, EditButton } from 'react-admin';
 * import RichTextInput from 'ra-input-rich-text';
 *
 * export const PostCreate = (props) => (
 *     <Create {...props}>
 *         <ModalForm>
 *             <TextInput source="title" />
 *             <TextInput source="teaser" options={{ multiLine: true }} />
 *             <RichTextInput source="body" />
 *             <DateInput label="Publication date" source="published_at" defaultValue={new Date()} />
 *         </ModalForm>
 *     </Create>
 * );
 *
 * @typedef {Object} Props the props you can use (other props are injected by Create or Edit)
 * @prop {ReactElement[]} children Input elements
 * @prop {Object} initialValues
 * @prop {Function} validate
 * @prop {boolean} submitOnEnter
 * @prop {string} redirect
 * @prop {ReactElement} toolbar The element displayed at the bottom of the form, contzining the SaveButton
 * @prop {string} variant Apply variant to all inputs. Possible values are 'standard', 'outlined', and 'filled' (default)
 * @prop {string} margin Apply variant to all inputs. Possible values are 'none', 'normal', and 'dense' (default)
 *
 * @param {Prop} props
 */
const ModalForm = props => (
  <FormWithRedirect
    {...props}
    render={formProps => <ModalFormView {...formProps} />}

  />
);

ModalForm.propTypes = {
  children: PropTypes.node,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.func]), // @deprecated
  initialValues: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  record: PropTypes.object,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func,
  saving: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  submitOnEnter: PropTypes.bool,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
  version: PropTypes.number,
  handleClose: PropTypes.func,
  saveButtonLabel: PropTypes.string,
};

const ModalFormView = ({
                         basePath,
                         children,
                         className,
                         handleSubmit,
                         handleSubmitWithRedirect,
                         invalid,
                         margin,
                         pristine,
                         record,
                         redirect,
                         resource,
                         saving,
                         submitOnEnter,
                         toolbar,
                         undoable,
                         variant,
                         handleClose,
                         saveButtonLabel,
                         ...rest
                       }) => { console.log("resource", resource);
                       return (

    <form
      className={classnames('simple-form', className)}
      {...sanitizeRestProps(rest)}
    >
      <DialogContent>

        {Children.map(
          children,
          input =>
            input && (
              <FormInput
                basePath={basePath}
                input={input}
                record={record}
                resource={resource}
                variant={input.props.variant || variant}
                margin={input.props.margin || margin}
              />
            )
        )}



  </DialogContent>
    <DialogActions>
      {toolbar &&
      React.cloneElement(toolbar, {
        handleClose,
        basePath,
        saveButtonLabel,
        handleSubmitWithRedirect,
        handleSubmit,
        invalid,
        pristine,
        record,
        redirect,
        resource,
        saving,
        submitOnEnter,
        undoable,
      })}
    </DialogActions>
    </form>
)
}

ModalFormView.propTypes = {
  basePath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  handleSubmit: PropTypes.func, // passed by react-final-form
  invalid: PropTypes.bool,
  pristine: PropTypes.bool,
  record: PropTypes.object,
  resource: PropTypes.string,
  redirect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.func,
  ]),
  save: PropTypes.func, // the handler defined in the parent, which triggers the REST submission
  saving: PropTypes.bool,
  submitOnEnter: PropTypes.bool,
  toolbar: PropTypes.element,
  undoable: PropTypes.bool,
  validate: PropTypes.func,
};

ModalFormView.defaultProps = {
  submitOnEnter: true,
  toolbar: <Toolbar/>,
};

const sanitizeRestProps = ({
                             anyTouched,
                             array,
                             asyncBlurFields,
                             asyncValidate,
                             asyncValidating,
                             autofill,
                             blur,
                             change,
                             clearAsyncError,
                             clearFields,
                             clearSubmit,
                             clearSubmitErrors,
                             destroy,
                             dirty,
                             dirtyFields,
                             dirtyFieldsSinceLastSubmit,
                             dirtySinceLastSubmit,
                             dispatch,
                             form,
                             handleSubmit,
                             hasSubmitErrors,
                             hasValidationErrors,
                             initialize,
                             initialized,
                             initialValues,
                             pristine,
                             pure,
                             redirect,
                             reset,
                             resetSection,
                             save,
                             setRedirect,
                             submit,
                             submitError,
                             submitErrors,
                             submitAsSideEffect,
                             submitFailed,
                             submitSucceeded,
                             submitting,
                             touch,
                             translate,
                             triggerSubmit,
                             undoable,
                             untouch,
                             valid,
                             validate,
                             validating,
                             _reduxForm,
                             ...props
                           }) => props;

export default ModalForm;
