import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Record } from 'ra-core';
import { TextField } from 'react-admin';

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string,
  disableEmpty?: boolean,
  link: string
}
const LinkField: FC<LinkFieldProps> = ({
                                          basePath = '',
                                          link,
                                          record,
                                          source,
                                          label,
                                          disableEmpty = false,
                                          ...rest
                                         }) => {
  return (disableEmpty && !record[source] 
    ? <TextField source={source} label={label}/>
    : <Link
        to={encodeURI(link
          .replaceAll('$id', record['id'] as string)

        )}
      >
        {record[source]}
      </Link>
  );
}



export default LinkField
