import React, { Children, isValidElement, cloneElement, Fragment, useState } from 'react';
import {
    ReferenceInput,
    List,
    Datagrid,
    Edit,
    Show,
    SelectInput,
    BooleanInput,
    Filter,
    ReferenceField,
    TextInput,
    FunctionField,
    Create,
    SimpleForm,
    DateField,
    TextField,
    TabbedShowLayout,
    Labeled,
    Pagination,
    TopToolbar, CreateButton,
    Tab,
    sanitizeListRestProps,
    ReferenceManyField,
    useDataProvider,
    useNotify,
    FilterButton,
} from 'react-admin';
import DateRangeFilterInput from '../../components/DateRangeFilterInput'
import { Typography, makeStyles } from '@material-ui/core'
import classnames from 'classnames';
import { Grid } from '@material-ui/core'
import BooleanField from "../../components/BooleanField";
import ScreenshotImageField from "../../components/ScreenshotImageField";
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import RelatedList from "../../components/RelatedList";
import VideoPlayerField from "../../components/VideoPlayerField";
import ResendButton from "./components/ResendButton";
import ExportButton from "./components/ExportButton";
import ResendManyButton from "./components/ResendManyButton";
import VideoLinkField from "../../components/VideoLinkField";
import RequestStatusField, { RequestStatusList } from "../../components/RequestStatusField";
import { RequestTypeList } from '../../components/FsspRequestTypeField';
import EmptyList from "../../components/EmptyList";
import FsspFormErrorsField from "../../components/FsspFormErrorsField";
import IdShowButtonField from "../../components/IdShowButtonField";
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'
import { useRecursiveTimeout } from '../../components/recursiveTimeout';
import BrowserSessionTypeField from '../../components/BrowserSessionTypeField';
import { useListContext } from 'react-admin';
import BrowserStatusField from '../../components/BrowserStatusField';
import LinkField from '../../components/LinkField';

const useStyles = makeStyles((theme) => ({
    filters: {
      marginTop: "2px"
    }
}));

const RequestFilters = [
    <SelectInput alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="status" label={'Статус'} variant={'outlined'}
                    choices={RequestStatusList}/>,
    <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="requestImportId" reference="import" 
                    perPage={200} variant={'outlined'} fullWidth={true}>
        <SelectInput optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy HH:mm', { locale: ru })} - ${record.fileName}`} variant={'outlined'}/>
    </ReferenceInput>,
    <TextInput source="surname||$contL" label={'Фамилия'} alwaysOn={true} variant={'outlined'}/>,
    <TextInput source="customId||$contL" alwaysOn={true} label={'Внутренний ID'} variant={'outlined'}/>,
    <TextInput source="caseNumber||$contL" alwaysOn={true} label={'Номер ИП'} variant={'outlined'}/>,
    <SelectInput alwaysOn={true} emptyText={'Не важно'} allowEmpty={true} source="hasResults"
                    label={'Есть Результаты'} variant={'outlined'} choices={[
        { id: '1', name: 'Есть результаты' },
        { id: '0', name: 'Нет результатов' }
    ]}/>,
    <SelectInput emptyText={'Не важно'} allowEmpty={true} source="legalDebtor"
                    label={'Является юридическим лицом'} variant={'outlined'} choices={[
        { id: 'true', name: 'Да' },
        { id: 'false', name: 'Нет' }
    ]}/>,
    <TextInput source="name||$contL" label={'Имя (название ЮЛ)'} variant={'outlined'}/>,
    <TextInput source="patronymic||$contL" label={'Отчество'} variant={'outlined'}/>,
    <TextInput source="region||$contL" label={'Регион'} variant={'outlined'}/>,
    <TextInput source="address||$contL" label={'Адрес предприятия-должника'} variant={'outlined'}/>,
    <TextInput source="numberIdSearch||$contL" label={'Номер ИД'} variant={'outlined'}/>,
    <TextInput source="inn||$contL" label={'ИНН юридического лица'} variant={'outlined'}/>,
    <DateRangeFilterInput source="birthDate||$between" label={'Дата рождения'}/>,
    <DateRangeFilterInput source="endProcessAt||$between" label="Дата обновления"/>,
    <DateRangeFilterInput source="sentAt||$between" label={'Дата проверки'}/>,
    <TextInput source="id" label={'ID'} variant={'outlined'}/>
]

const RequestFsspMainFilter = (props) => {
    const classes = useStyles();
    return (<Filter {...props} className={classes.filters}>
        {RequestFilters}
    </Filter>);
}

const RequestBulkActionButtons = props => (
    <Fragment>
        <ExportButton {...props}    />
        <ResendManyButton label="Остановить" {...props} cancel={true}/>
        <ResendManyButton label="Переотправить" {...props} />
    </Fragment>
);

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showCreate,
                         showFilter,
                         maxResults,
                         //filters,
                         //displayedFilters,
                         //filterValues,
                         //total,
                         //permanentFilter,
                         ...rest
                    }: any) => {
    const { data, sort, total, filterValues } = useListContext()
    return (<TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        <FilterButton filters={RequestFilters}/>
        {showCreate ? <CreateButton basePath={basePath}/> : null}
        <ExportButton disabled={total === 0}
                      label={'Экспорт по фильтру'}
                      resource={resource}
                      sort={currentSort}
                      filter={filterValues}
                      total={total}
                      {...rest}    />
        <ResendButton disabled={total === 0}
                  cancel={true}
                  label={'Остановить по фильтру'}
                  resource={resource}
                  sort={currentSort}
                  filter={filterValues}
                  total={total}
                  {...rest}    />
        <ResendButton disabled={total === 0}
                      label={'Переотправить по фильтру'}
                      resource={resource}
                      sort={currentSort}
                      filter={filterValues}
                      total={total}
                      {...rest}    />
    </TopToolbar>);
}

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
    showCreate: true
};

export const RequestFsspMainList = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const [count, setCount] = useState(0);
    const getSystemOverloadStatus = async () => {
        setCount(count+1);
        const {data, isLoading, eror} = await dataProvider.getOne('browser-session/last', { id: 'status'});
        console.log(data);
        if (data.totalCount > 5 && data.systemOverloadCount > (data.threshold !== undefined ? data.threshold : data.totalCount * 0.5)) {
            const status =  (data.systemOverloadCount >= data.totalCount * 0.9 ? 'На сайте ФССП технические работы, отправка возобновится после их окончания' : `Сайт ФССП перегружен, скорость отправки снижена${data.isAdmin ? (' ('+data.systemOverloadCount +'/'+ data.totalCount +')') : ''}`);
            if (!isLoading)
                notify(status, { type: 'warning' });
            //return status;
        }
    }
    const onError = (error) => {
        notify(`Could not load list: ${error.message}`, { type: 'warning' });
    };
    useRecursiveTimeout(() => getSystemOverloadStatus(), 12000);
    if (!count) { getSystemOverloadStatus(); }
    return (<List {...props} title={'Заявки ФССП'} filters={<RequestFsspMainFilter/>}
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
          actions={<ListActions showCreate={false}/>}
          bulkActionButtons={<RequestBulkActionButtons/>}
          empty={<EmptyList title={'Заявки отсутствуют'}
                            description={'Для того чтобы начать работать импортируйте заявки'}/>}
          queryOptions={{ onError }}>
        <Datagrid>
            <IdShowButtonField label={'Id'}/>
            <TextField source="customId" label={'ID внутренний'}/>
            <TextField source="caseNumber" label={'Номер ИП'}/>
            <RequestStatusField source="status" label={'Статус'}/>
            {/*<LinkToRelatedResults source="hasResults" label={'Подходящие результаты'}/>*/}
            <LinkField source="hasResults" label={'Подходящие результаты'}
                 link={'/request/fssp_main/$id/show/results'} disableEmpty/>
            <TextField source="surname" label={'Фамилия'}/>
            <TextField source="name" label={'Имя (название ЮЛ)'}/>
            <TextField source="patronymic" label={'Отчество'}/>
            <TextField source="birthDate" label={'Дата рождения'}/>
            <TextField source="numberIdSearch" label={'Номер ИД'}/>
            <TextField source="inn" label={'ИНН ЮЛ'}/>
            <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
                <FunctionField render={(record) => `#${record.id} (${record.fileName}${record.user ? (', ' + record.user.name) : ''})`} />
            </ReferenceField>
            <DateField source={'completedAt'} label={'Проверен'} showTime/>
            <DateField source={'endProcessAt'} label={'Обновлен'} showTime/>
            {/*<ScreenshotLinkField source="browserSession.screenshot" label={'Скриншот'}/>}*/}
        </Datagrid>
    </List>);
}

const TitleShow = ({ record }: any) => {
    return (
        <span>
      Заявка ФССП № {record.id}
    </span>
    );
};
const SanitizedGrid = ({ ...props }) => {
    console.log("props.children", props.children)
    const newProps = {
        record: (props as any).record,
        resource: (props as any).resource,
        basePath: (props as any).basePath,
    };
    return (
        <Grid item={props.item} md={props.md} container={props.container}>
            {Children.map(props.children, field =>
                field && isValidElement(field) ? (
                    ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, { ...props, ...(field.props as any) }) :
                        <div
                            key={(field.props as any).source}
                            className={classnames(
                                `ra-field ra-field-${(field.props as any).source}`,
                                (field.props as any).className
                            )}
                        >
                            {(field.props as any).addLabel ? (
                                <Labeled
                                    record={props.record}
                                    resource={props.resource}
                                    basePath={props.basePath}
                                    label={(field.props as any).label}
                                    source={(field.props as any).source}
                                    disabled={false}
                                >
                                    {field}
                                </Labeled>
                            ) : typeof field.type === 'string' ? (
                                field
                            ) : (
                                cloneElement(field, newProps)
                            )}
                        </div>
                ) : null
            )}
        </Grid>
    );
};

export const RequestFsspMainShow = (props) => (
    <Show {...props} title={<TitleShow/>}>


        <TabbedShowLayout>
            <Tab label="Обращение">

                <Typography variant="subtitle1" gutterBottom>
                    Информация о подаче
                </Typography>
                <SanitizedGrid container spacing={4}>
                    <SanitizedGrid container={false} item md={4}>
                        <TextField source="customId" label={'ID внутренний'}/>
                        <TextField source="caseNumber" label={'Номер ИП'}/>
                        <TextField source="surname" label={'Фамилия'}/>
                        <TextField source="name" label={'Имя (название ЮЛ)'}/>
                        <TextField source="patronymic" label={'Отчество'}/>
                        <TextField source="birthDate" label={'Дата рождения'}/>
                        <TextField source="region" label={'Регион'}/>
                        <TextField source="address" label={'Адрес предприятия-должника'}/>
                        <TextField source="numberIdSearch" label={'Номер ИД'}/>
                        <TextField source="inn" label={'ИНН юридического лица'}/>
                        <BooleanField source="legalDebtor" label={'Является юридическим лицом'}/>
                    </SanitizedGrid>
                    <SanitizedGrid container={false} item md={4}>
                        <TextField source="id" label={'ID обращения'}/>
                        <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
                            <TextField source="id"/>
                        </ReferenceField>
                        <DateField source={'completedAt'} label={'Проверен'} showTime/>
                        <DateField source={'endProcessAt'} label={'Обновлен'} showTime/>
                        <RequestStatusField source="status" label={'Статус подачи'} addLabel={true}/>
                        <TextField source="countResults" label={'Общее количество результатов'}/>
                        <TextField source="hasResults" label={'Подходящие результаты'}/>
                    </SanitizedGrid>

                </SanitizedGrid>


            </Tab>


            <Tab label="Результаты" path="results">
                <ReferenceManyField reference="request-result-all" target="requestId" addLabel={false} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
                    <RelatedList
                        hasCreate={false}
                        emptyTitle={'Результаты отсутствуют'}
                    >
                        <TextField source="debtor" label={'Должник'}/>
                        <TextField source="caseNumberFull" label={'Исполнительное производство'}/>
                        <TextField source="caseNumber" label={'Номер ИП'}/>
                        <TextField source="dateIp" label={'Дата ИП'}/>
                        <TextField source="sdNumber" label={'Номер СД'}/>
                        <TextField source="requisites" label={'Реквизиты исполнительного документа'}/>
                        <TextField source="kindId" label={'Вид ИД'}/>
                        <TextField source="dateId" label={'Дата ИД'}/>
                        <TextField source="numberId" label={'Номер ИД'}/>
                        <TextField source="endDate" label={'Дата окончания или прекращения ИП'}/>
                        <TextField source="reason" label={'Причина окончания или прекращения ИП'}/>
                        <TextField source="subjectAndAmount"
                                   label={'Предмет исполнения, сумма непогашенной задолженности'}/>
                        <TextField source="amount"
                                   label={'Cумма непогашенной задолженности'}/>
                        <TextField source="department" label={'Отдел судебных приставов'}/>
                        <TextField source="bailiff" label={'Судебный пристав-исполнитель'}/>


                    </RelatedList>

                </ReferenceManyField>
            </Tab>
            <Tab label="Ошибки" path="errors">


                <RequestStatusField source="status" label={'Статус подачи'} addLabel={true}/>
                <TextField source="remoteStatus" label={'Статус ФССП'}/>

                <FunctionField label="Прогресс"
                               render={record => `${record.browserSession && record.browserSession.currentStep ? Math.ceil((record.browserSession.currentStep / record.browserSession.totalSteps) * 100) + '%' : ''}`}/>

                <FsspFormErrorsField source={'formErrors'} label={'Ошибки'}></FsspFormErrorsField>
                <ScreenshotImageField source="browserSession.screenshot" label={'Скриншот ошибки'}/>
                <VideoLinkField source="browserSession.video" label={'Видео'} addLabel={true}/>
                <VideoPlayerField source="browserSession.video" label={'Видео'}/>
            </Tab>

            <Tab label="Сессии">
                <ReferenceManyField reference="browser-session" target="requestId" addLabel={false} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
                <RelatedList hasCreate={false}
                            emptyTitle={'Сессии отсутствуют'}
                            sort={{ field: 'id', order: 'DESC' }}
                >

                    <TextField source="id"/>
                    <BrowserSessionTypeField source="requestType" label={'Тип запроса'}/>
                    <BrowserStatusField source="status" label={'Статус'}/>
                    <DateField source={'createdAt'} label={'Создан'} showTime/>
                    <DateField source={'startAt'} label={'Старт'} showTime/>
                    <DateField source={'finishAt'} label={'Финиш'} showTime/>
                    <FunctionField label="Прогресс"
                                render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`}/>
                    <TextField source={'currentStepName'} label={'Шаг'}/>
                    <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
                    <VideoLinkField source={"video"} label={'Видео'} addLabel={true}/>
                </RelatedList>

                </ReferenceManyField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);


export const RequestFsspMainEdit = (props) => (
    <Edit title={<TitleShow/>} {...props} >
        <SimpleForm redirect={'show'}>
            <TextInput source="customId" label={'ID внутренний'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="caseNumber" label={'Номер ИП'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="surname" label={'Фамилия'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="name" label={'Имя (название ЮЛ)'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="patronymic" label={'Отчество'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="birthDate" label={'Дата рождения (ДД.ММ.ГГГГ)'} variant={'outlined'}
                       fullWidth={true}/>
            <TextInput source="region" label={'Регион'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="address" label={'Адрес предприятия-должника'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="numberId" label={'Номер ИД'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="inn" label={'ИНН юридического лица'} variant={'outlined'} fullWidth={true}/>
            <BooleanInput source="legalDebtor" label={'Является юридическим лицом'}/>
            <BooleanInput label="Включить видео" source="enableVideo"/>

        </SimpleForm>
    </Edit>
);

export const RequestFsspMainCreate = (props) => (
    <Create title="Создать заявку ФССП" {...props} >
        <SimpleForm redirect={'list'}>
            <SelectInput source="type" label={'Тип заявки'} variant={'outlined'} fullWidth={true} choices={RequestTypeList}/>
            <TextInput source="customId" label={'ID внутренний'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="caseNumber" label={'Номер ИП'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="surname" label={'Фамилия'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="name" label={'Имя (название ЮЛ)'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="patronymic" label={'Отчество'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="birthDate" label={'Дата рождения (ДД.ММ.ГГГГ)'} variant={'outlined'}
                       fullWidth={true}/>
            <TextInput source="region" label={'Регион'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="address" label={'Адрес предприятия-должника'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="numberId" label={'Номер ИД'} variant={'outlined'} fullWidth={true}/>
            <TextInput source="inn" label={'ИНН юридического лица'} variant={'outlined'} fullWidth={true}/>
            <BooleanInput source="legalDebtor" label={'Является юридическим лицом'}/>
            <BooleanInput label="Включить видео" source="enableVideo"/>
        </SimpleForm>
    </Create>
);
