import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum RequestStatus {
  Created = 'created',
  InQueue = 'in_queue',
  InProgress = 'in_progress',
  Error = 'error',
  Sent = 'sent',
  Completed = 'completed',
  Canceled = 'canceled',
}
export const RequestStatusList = [
  {id: RequestStatus.Created, name: 'Создан' },
  {id: RequestStatus.InQueue, name: 'В очереди' },
  {id: RequestStatus.InProgress, name: 'В процессе' },
  {id: RequestStatus.Sent, name: 'Отправлен' },
  {id: RequestStatus.Completed, name: 'Исполнен' },
  {id: RequestStatus.Error, name: 'Ошибка' },
  {id: RequestStatus.Canceled, name: 'Ошибка распознавания капчи' },
]

const RequestStatusField = ({source, record = {}, 
  colors = {
    'created': 'success',
    'in_progress': 'warning',
    'in_queue': 'warning',
    'completed': 'success',
    'error': 'error',
    'sent': 'warning',
    'canceled': 'warning',
  } }: {labels?: any, source?: any, record?: any, colors?: any}) =>
  <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{RequestStatusList.find((rs) => rs.id === record[source])?.name || record[source]}</Typography>;

RequestStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default RequestStatusField;
