import React from 'react';
import { FC, useState } from 'react';
import { Record } from 'ra-core';
import {
  Show,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Create,
  SimpleForm,
  DateField,
  TextField,
  TextInput,
  FileInput,
  FileField,
  RadioButtonGroupInput,
  required,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  TopToolbar,
  useNotify,
  useDataProvider,
  FunctionField,
  DeleteButton,
  usePermissions,
  FormDataConsumer
} from 'react-admin';
import FormGroup from '@material-ui/core/FormGroup';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Box from '@material-ui/core/Box';
import LinkField from "../../components/LinkField";
import UpdateButton from "./components/UpdateButton";
import RelatedList from "../../components/RelatedList";
import FsspRequestTypeField, { FsspRequestType, RequestTypeList } from "../../components/FsspRequestTypeField";
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import ExportStatusField, { ExportStatusList } from '../../components/ExportStatusField';
import IdShowButtonField from "../../components/IdShowButtonField";
import Link from '@material-ui/core/Link';
import { Link as LinkRouter } from 'react-router-dom';
import { stringify } from 'query-string';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { useRecursiveTimeout } from '../../components/recursiveTimeout';
import DateRangeFilterInput from '../../components/DateRangeFilterInput';
import { Pagination } from 'react-admin';
import { useRecordContext } from 'react-admin';

const ImportFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'id'}  variant={'outlined'}/>
    <TextInput source="fileName||$contL" label={'Файл'} alwaysOn variant={'outlined'}/>
    <DateRangeFilterInput source="createdAt||$between" label="Дата создания" alwaysOn/>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={ExportStatusList} />
    <SelectInput source="type" label={'Тип заявки'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={RequestTypeList} />
  </Filter>
);

interface LinkFieldProps {
  basePath?: string;
  record?: Record;
  source?: string
  label?: string
}
const LinkToRelatedRequests: FC<LinkFieldProps> = ({
                                           basePath = '',
                                           label,
                                           record,
                                           source,
                                                         ...rest
                                         }) => {

  return record?.importedRows ? (<LinkRouter
    to={'/request/fssp_main?'+stringify({
      page: 1,
      perPage: 25,
      sort: 'id',
      order: 'DESC',
      filter: JSON.stringify({ requestImportId: record.id }),
    })}
  >
    {record[source]}
  </LinkRouter>) : (<TextField source={source} label={label}/>)
}

export const ImportList = (props) => {
  const dataProvider = useDataProvider();
    const notify = useNotify();
    const [count, setCount] = useState(0);
    const getSystemOverloadStatus = async () => {
        setCount(count+1);
        const {data, isLoading, eror} = await dataProvider.getOne('browser-session/last', { id: 'status'});
        console.log(data);
        if (data.totalCount > 5 && data.systemOverloadCount > (data.threshold !== undefined ? data.threshold : data.totalCount * 0.5)) {
            const status =  (data.systemOverloadCount >= data.totalCount * 0.9 ? 'На сайте ФССП технические работы, отправка возобновится после их окончания' : `Сайт ФССП перегружен, скорость отправки снижена${data.isAdmin ? (' ('+data.systemOverloadCount +'/'+ data.totalCount +')') : ''}`);
            if (!isLoading)
                notify(status, { type: 'warning' });
            //return status;
        }
    }
    const onError = (error) => {
        notify(`Could not load list: ${error.message}`, { type: 'warning' });
    };
    useRecursiveTimeout(() => getSystemOverloadStatus(), 12000);
    if (!count) { getSystemOverloadStatus(); }
    return (<List {...props} title="Импорты" filters={<ImportFilter />} actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}
        pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
        empty={<EmptyList title={'Импорты отсутсвуют'} description={'Импортируйте заявки нажав кнопку «Импортировать»'} buttonText={'Импортировать'}/>}
        queryOptions={{ onError }}>
    <Datagrid>
      <IdShowButtonField source="id" />
      <TextField source="fileName" label={'Файл'}/>

      <FsspRequestTypeField source="type" label={'Тип заявки'}/>
      <ExportStatusField source="status" label={'Статус'} />
      <TextField source="totalRows" label={'Всего'}/>
      <LinkToRelatedRequests source="importedRows" label={'Импортировано'} />
      <LinkField source="errorRows" label={'Ошибок'}
                 link={'/import/$id/show/errors'} disableEmpty/>
      <UpdateButton label={''}/>
      <FunctionField label={'Создан'} render={(record) => `${record.user ? (record.user.name + ', ') : ''}${format(new Date(record.createdAt ? record.createdAt : Date.now()), 'dd.MM.yyyy HH:mm:ss', { locale: ru })}`}/>
    </Datagrid>
  </List>);
}
const TitleShow = ({ record } : any) => {
  return (
    <span>
      Импорт заявок № {record.id}
    </span>
  );
};

const ImportFileField = ({ record } : any) => {
  //const record = useRecordContext()
  //record.file = record.file.url ? record.file : {name: record.fileName, url: `${process.env.REACT_APP_API_URL || ''}/api/import/${record.id}/${record.file}`};
  return (
    <Box p={1} paddingLeft={0}><FileField source="file.url" title="file.name" /> </Box>
  );
};

const ImportShowActions = (props) => {
  const record = useRecordContext()
  const { permissions } = usePermissions();
  return (
    <TopToolbar>
      <UpdateButton {...props} record={record}/>
      {(permissions !== 'super_admin') ? null : <DeleteButton label={''} record={record} mutationMode="pessimistic" redirect={false}/>}
    </TopToolbar>
  );
};

export const ImportShow = (props) => (
  <Show {...props} title={<TitleShow/>} actions={<ImportShowActions/>}>
    <TabbedShowLayout>
      <Tab label="Данные импорта">
        <TextField source="id" />
        <FsspRequestTypeField source="type" label={'Тип заявки'}/>
        <ImportFileField />
        <ExportStatusField source="status" label={'Статус'} />
        <TextField source="totalRows" label={'Всего'}/>
        <TextField source="importedRows" label={'Импортировано'}/>
        <TextField source="errorRows" label={'Ошибок'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Ошибки импорта" path="errors">
        <ReferenceManyField reference="import-error" target="requestImportId" addLabel={false} pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}>
          <RelatedList hasCreate={false}
                       emptyTitle={'Ошибки отсутствуют'}
                   >

            <TextField source="row" label={'№ Строки'}/>
            <TextField source="error" label={'Ошибка'}/>
          </RelatedList>

        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>

  </Show>
);

const formDataConsumer = (types: string[]) => (
  <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => types.includes(formData.type) ? (
          <FormGroup>
            <FormControl>
              <Link href={`/import-templates/${formData.type}.xlsx`} download={`${formData.type}_2023-11-01.xlsx`} onClick={() => {}}>Скачать пример файла (от 01.11.2023)</Link>
              <FormHelperText id={`${formData.type}-helper-text`}>Заполните файл по образцу</FormHelperText>
            </FormControl>
          </FormGroup>
        ) : null}
  </FormDataConsumer>
)

export const ImportCreate = (props) => {
  const notify = useNotify();
  const onError = (error) => {
    notify(`Не удается создать импорт: ${error.message}`, { type: 'error' });
  };
  return (<Create title="Импорт заявок" {...props} onFailure={onError}>
    <SimpleForm redirect={'list'}>
      <RadioButtonGroupInput source="type" label={'Тип заявки'} choices={RequestTypeList} validate={required()} variant={'outlined'} fullWidth={true}/>
      {formDataConsumer(Object.values(FsspRequestType))}
      <FileInput source="file" multiple={false} label="Файл импорта" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
        <FileField source="src" title="title" />
      </FileInput>
    </SimpleForm>
  </Create>);
}

