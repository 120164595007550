import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum BrowserStatus {
  Created = 'created',
  InProgress = 'in_progress',
  NoResults = 'no_results',
  Completed = 'completed',
  Error = 'error',
  SystemOverloaded = 'system_overloaded',
  ErrorCaptcha = 'error_captcha',
  ErrorProxy = 'error_proxy',
  TooManyRequests = 'too_many_requests',
}

export const BrowserStatusList = [
  {id: BrowserStatus.Created, name: 'Создан' },
  {id: BrowserStatus.InProgress, name: 'В процессе' },
  {id: BrowserStatus.NoResults, name: 'Не найдено' },
  {id: BrowserStatus.Completed, name: 'Исполнен' },
  {id: BrowserStatus.Error, name: 'Ошибка ФССП' },
  {id: BrowserStatus.SystemOverloaded, name: 'Сайт перегружен' },
  {id: BrowserStatus.ErrorCaptcha, name: 'Ошибка распознавания капчи' },
  {id: BrowserStatus.ErrorProxy, name: 'Ошибка прокси' },
  {id: BrowserStatus.TooManyRequests, name: 'Слишком много запросов' },
]

const BrowserStatusField = ({
                          source, record = {}, colors = {
    'created': 'success',
    'in_progress': 'warning',
    'no_results': 'warning',
    'completed': 'success',
    'error': 'error',
    'system_overloaded': 'error',
    'error_captcha': 'error',
    'error_proxy': 'warning',
    'too_many_requests': 'warning',
    //'finished': 'success',
    //'stopped': 'error',
  } }: {labels?: any, source?: any, record?: any, colors?: any}) =>
  <Typography variant={'body2'} color={colors[record[source]] || 'warning'}>{BrowserStatusList.find((rs) => rs.id === record[source])?.name || record[source]}</Typography>;

BrowserStatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BrowserStatusField;
