import React, { cloneElement, Fragment } from 'react'
import { BulkDeleteButton } from 'react-admin';
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput, NumberInput, TopToolbar, sanitizeListRestProps, CreateButton,
  Pagination,
} from 'react-admin'
import DateRangeFilterInput from '../../components/DateRangeFilterInput';
import EmptyList from "./components/EmptyList";
import ImportButton from "./components/ImportButton";
import UnblockManyButton from './components/UnblockManyButton';

const ProxyFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'}/>
    <TextInput source="proxy||$contL" label={'Поиск'} alwaysOn={true} resettable={true} variant={'outlined'}/>
    <SelectInput
      source="status"
      label={'Статус'}
      alwaysOn={true}
      variant={'outlined'}
      emptyText={'Не применять'} allowEmpty={true}
      choices={[
        { id: 'blocked', name: 'Заблокировано' },
        { id: 'active', name: 'Активно' },
      ]}
    />
    <DateRangeFilterInput source="createdAt||$between" label="Дата добавления" alwaysOn/>
  </Filter>
)

const ListActions = ({
                       currentSort,
                       className,
                       resource,
                       filters,
                       displayedFilters,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       filterValues,
                       permanentFilter,
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       showFilter,
                       maxResults,
                       total,
                       showCreate,
                       ...rest
                     }: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    {showCreate ? <CreateButton basePath={basePath}/> : null}
    <ImportButton resource={resource}
                   sort={currentSort}
                   filter={{ ...filterValues, ...permanentFilter }}
                   total={total}
                  label={'Импорт'}
                   {...rest}    />
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

const ProxyBulkActionButtons = props => (
  <Fragment>
    <UnblockManyButton label="Разблокировать" {...props}/>
    <UnblockManyButton label="Блокировать" {...props} block={true} />
    <BulkDeleteButton {...props}/>
  </Fragment>
);

export const ProxyList = (props) => (
  <List
    {...props}
    title="Прокси"
    filters={<ProxyFilter/>}
    actions={<ListActions/>}
    pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>}
    bulkActionButtons={<ProxyBulkActionButtons/>} 
    sort={{ field: 'id', order: 'DESC' }}
    empty={<EmptyList title={'Список прокси пуст'} description={'Добавить прокси или импортируйте Excel файл'}/>}
   >

    <Datagrid rowClick={'edit'}>

      <TextField source="id" label={'ID'}/>
      <TextField source="status" label={'Статус'}/>
      <TextField source="proxy" label={'Прокси'}/>
      <TextField source="countTries" label={'Использование'}/>
      <TextField source="createdAt" label={'Добавлен'}/>

    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Прокси: {record.proxy}
    </span>
  )
}

const ProxyEditForm = (props) => (
  <SimpleForm {...props} redirect={'list'}>
    <TextInput source="proxy" label={'Адрес прокси'} fullWidth={true} validate={required()} variant={'outlined'}/>
    <SelectInput
      source="status"
      label={'Статус'}
      fullWidth={true}
      variant={'outlined'}
      choices={[
        { id: 'blocked', name: 'Заблокирован' },
        { id: 'active', name: 'Активен' }
      ]}
    />
    <NumberInput source="countTries" label={'Использование'} variant={'outlined'} fullWidth={true} />
  </SimpleForm>
);

export const ProxyEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
      <ProxyEditForm />
  </Edit>
)

export const ProxyCreate = (props) => (
  <Create title="Добавить прокси" {...props}>
    <ProxyEditForm />
  </Create>
)
