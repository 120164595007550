import React, { FC, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
} from '@material-ui/core';
import { useNotify } from 'ra-core';
import {
  FileInput,
  FileField,
  CREATE,
  showNotification,
  useRefresh,
} from 'react-admin';
import { dataProvider } from "../../../common/dataProvider";

import ModalForm from "../../../components/ModalForm";

import {
  required,
} from "react-admin";


import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  gridItem: {
    width: '50%'
  }
}))

interface Props {
  basePath,
  resource,
  show,
  filter?: any,
  handleClose,
}


const ImportModal: FC<Props> = ({
                                  basePath,
                                  resource,
                                  show,

                                  filter,
                                  handleClose,
                                  ...rest
                                }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };



const save = useCallback((values) => {
  setSaving(true);
  dataProvider(CREATE, 'proxy-import', {  data: values })
    .then(() => {
      console.log("Updated")
      setSaving(false);
      notify('Прокси импортированы', 'info')
      handleClose()
      refresh();

    })
    .catch((e) => {
      console.error(e);
      showNotification('Ошибка', 'warning')
      setSaving(false);
    });
}, [])



  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Обновить"
    >
      <DialogTitle>Импорт прокси</DialogTitle>
      <ModalForm
        handleClose={handleCloseClick}
        save={save}
        saving={saving}
      >
        <FileInput source="file" multiple={false} label="Файл импорта" accept={["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",'.csv']} placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
          <FileField source="src" title="title" />
        </FileInput>
      </ModalForm>

    </Dialog>
  );
}


ImportModal.propTypes = {
  basePath: PropTypes.string,
  show: PropTypes.bool,
  handleClose: PropTypes.func,

  filter: PropTypes.object,

};

export default ImportModal;
