import Cookies from 'js-cookie'
import { HttpError } from 'react-admin'
import ApiProvider from './ApiProvider'
import { serialize } from 'object-to-formdata'


export interface Options extends RequestInit {
  user?: {
    authenticated?: boolean;
    token?: string;
  };
}

export const createHeadersFromOptions = (options: Options): Headers => {
  const requestHeaders = (options.headers ||
    new Headers({
      Accept: 'application/json',
    })) as Headers;
  if (
    !requestHeaders.has('Content-Type') &&
    !(options && (!options.method || options.method === 'GET')) &&
    !(options && options.body && options.body instanceof FormData)
  ) {
    requestHeaders.set('Content-Type', 'application/json');
  }
  if (options.user && options.user.authenticated && options.user.token) {
    requestHeaders.set('Authorization', options.user.token);
  }

  return requestHeaders;
};

const fetchJson = (url, options: Options = {}) => {
  const requestHeaders = createHeadersFromOptions(options);

  return fetch(url, { ...options, headers: requestHeaders })
    .then(response =>
      response.text().then(text => ({
        status: response.status,
        statusText: response.statusText,
        headers: response.headers,
        body: text,
      }))
    )
    .then(({ status, statusText, headers, body }) => {
      let json;
      try {
        json = JSON.parse(body);
      } catch (e) {
        // not json, no big deal
      }
      if (status < 200 || status >= 300) {
        console.log("Json res", json)
        return Promise.reject(
          new HttpError(
            (json && json.errors  && Array.isArray(json.errors) && json.errors.length > 0) ? json.errors[0]
              : (json && json.errors  && !Array.isArray(json.errors) ? json.errors :  ( (json && json.message) || statusText ) ),

            status,
            json
          )
        );
      }
      return Promise.resolve({ status, headers, body, json });
    });
};

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' })
  }
  const token = Cookies.get('auth-token')
  options.headers.set('Authorization', `Bearer ${token}`)
  return fetchJson(url, options)
}


/**
 * For posts update only, convert uploaded image in base 64 and attach it to
 * the `picture` sent property, with `src` and `title` attributes.
 */
const addUploadFeature = requestHandler => (type, resource, params) => {


  if (type === 'UPDATE' || type === 'CREATE') {
    // notice that following condition can be true only when `<ImageInput source="pictures" />` component has parameter `multiple={true}`
    // if parameter `multiple` is false, then data.pictures is not an array, but single object
    console.log("PARAMS", params.data);
    const newPictures = [];

    const newData = {};
    let hasFile = false;
    for (let key of Object.keys(params.data)) {
      if (params.data[key] && params.data[key].rawFile instanceof File) {
        newData[key] = params.data[key].rawFile;
        hasFile = true;
      }else{
        newData[key] = params.data[key];
      }
    }

    if (hasFile) {
      console.log("HasFile", hasFile, newData)
      params.data = serialize(
        newData,
        {
          indices: true,
        }
      );
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};


// by default
export const dataProvider = addUploadFeature(ApiProvider(`${process.env.REACT_APP_API_URL || ''}/api`, httpClient))



