import * as React from 'react';
import { useCallback, useContext, useState, FunctionComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import {
    fetchRelatedRecords,
    useDataProvider,
    useNotify,
    ExporterContext,
    Exporter,
} from 'ra-core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from 'react-admin'

import GetAppIcon from '@material-ui/icons/GetApp';
const DownloadButton: FunctionComponent<DownloadButtonProps> = ({
                                                              basePath = '',
                                                              record,
                                                              ...rest
                                                          }) => {

    const dataProvider = useDataProvider();
    const notify = useNotify();

    const handleClick = () => {
        dataProvider
            .getOne(`export/sign`, {
                id: record.id,
            })
            .then(
                ({ data }) => {
                    console.log("get data", data)
                    const link = data.url as any;
                    window.location.href = link;

                }
            )
            .catch(error => {
                console.error(error);
                notify('ra.notification.http_error', 'warning');
            });
    }



    return (
        <Button
            onClick={handleClick}
            label={'Скачать'}
        >
            <GetAppIcon/>
        </Button>
    );
};

interface Props {
    basePath?
    record?
}

export type DownloadButtonProps = Props & ButtonProps;

DownloadButton.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.any,
};

export default DownloadButton;
