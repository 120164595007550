import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

export enum FsspRequestType {
  Main = 'fssp_main', //Main = FL
  Ip = 'ip',
  //FL = 'physical',
  UL = 'legal',
  ID = 'id',
  INN = 'inn',
}

export const RequestTypeList = [
  { id: FsspRequestType.Main, name: 'Поиск ФЛ' },
  { id: FsspRequestType.UL, name: 'Поиск ЮЛ' },
  { id: FsspRequestType.Ip, name: 'Поиск по ИП' },
  { id: FsspRequestType.ID, name: 'Поиск по ИД' },
  { id: FsspRequestType.INN, name: 'Поиск по ИНН ЮЛ' },
]

const FsspRequestTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{RequestTypeList.find((rs) => rs.id === record[source])?.name || ''}</Typography>;

FsspRequestTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default FsspRequestTypeField;
