import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
const labels = {
'request': 'Запрос через браузер' ,
'ajax': 'Запрос ajax',
}
const BrowserSessionTypeField = ({
                          source, record = {}
                        }) =>
  <Typography variant={'body2'} >{labels[record[source]] || record[source]}</Typography>;

BrowserSessionTypeField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default BrowserSessionTypeField;
