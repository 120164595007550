import React, { Children, isValidElement, cloneElement, Fragment } from 'react';
import {
    ReferenceInput,
    List,
    Datagrid,
    Show,
    SelectInput,
    SimpleShowLayout,
    Filter,
    TextInput,
    DateField,
    TextField,
    Labeled,
    Pagination,
    TopToolbar, CreateButton,
    sanitizeListRestProps,
    NumberInput
} from 'react-admin';
import DateRangeFilterInput from '../../components/DateRangeFilterInput';
import classnames from 'classnames';
import { Grid } from '@material-ui/core'
import ExportButton from "./components/ExportButton";
import EmptyList from "../../components/EmptyList";
import IdShowButtonField from "../../components/IdShowButtonField";
import format from 'date-fns/format'
import { ru } from 'date-fns/locale'

const ResultFsspFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="request.requestImportId" reference="import" 
                        perPage={200} variant={'outlined'} fullWidth={true}
        >
            <SelectInput
                optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy HH:mm', { locale: ru })} - ${record.fileName}`}/>
        </ReferenceInput>
        <TextInput source="numberId||$contL" label={'Номер ИД'} alwaysOn={true} variant={'outlined'}/>
        <SelectInput alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="kindId" label={'Вид ИД'} variant={'outlined'}
                     choices={[
                         {name: "Акт другого органа", id: "Акт другого органа"},
                         {name: "Акт органа, осуществляющего контрольные функции", id: "Акт органа, осуществляющего контрольные функции"},
                         {name: "Судебный приказ", id: "Судебный приказ"},
                         {name: "Судебный акт по делу об административном правонарушении", id: "Судебный акт по делу об административном правонарушении"},
                         {name: "Исполнительный лист", id: "Исполнительный лист"},
                         {name: "Акт по делу об административном правонарушении", id: "Акт по делу об административном правонарушении"},
                         {name: "Постановление судебного пристава-исполнителя", id: "Постановление судебного пристава-исполнителя"},
                         {name: "Исполнительная надпись нотариуса", id: "Исполнительная надпись нотариуса"},
                         {name: "Исполнительный документ", id: "Исполнительный документ"},
                         {name: "Нотариально удостоверенное соглашение об уплате алиментов", id: "Нотариально удостоверенное соглашение об уплате алиментов"},
                     ]}/>
        <TextInput source="debtor||$contL" label={'Должник'} alwaysOn={true} variant={'outlined'}/>
        <TextInput source="request.customId||$contL" alwaysOn={true} label={'Внутренний ID'} variant={'outlined'}/>
        <NumberInput source="requestId" label={'ID заявки'} variant={'outlined'}/>
        <TextInput source="caseNumber||$contL" alwaysOn={true} label={'Номер ИП'} variant={'outlined'}/>
        <DateRangeFilterInput source="request.completedAt||$between" label={'Дата проверки'} variant={'outlined'}/>
        <TextInput source="request.kindId" label={'Вид ИД'} variant={'outlined'}/>
        <TextInput source="amount" label={'Cумма непогашенной задолженности'} variant={'outlined'}/>
    </Filter>
);

const ResultBulkActionButtons = props => (
    <Fragment>
        <ExportButton {...props}    />
    </Fragment>
);

const ListActions = ({
                         currentSort,
                         className,
                         resource,
                         filters,
                         displayedFilters,
                         exporter, // you can hide ExportButton if exporter = (null || false)
                         filterValues,
                         permanentFilter,
                         hasCreate, // you can hide CreateButton if hasCreate = false
                         basePath,
                         selectedIds,
                         onUnselectItems,
                         showFilter,
                         maxResults,
                         total,
                         showCreate,
                         ...rest
                     }: any) => (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
        {filters && cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {showCreate ? <CreateButton basePath={basePath}/> : null}
        <ExportButton disabled={total === 0}
                      resource={resource}
                      sort={currentSort}
                      filter={{ ...filterValues, ...permanentFilter }}
                      total={total}
                      {...rest}    />

    </TopToolbar>
);

ListActions.defaultProps = {
    selectedIds: [],
    onUnselectItems: () => null,
    showCreate: true
};
export const ResultList = (props) => (
    <List {...props} title="Результат" filters={<ResultFsspFilter/>}
          actions={<ListActions showCreate={false}/>}
          bulkActionButtons={<ResultBulkActionButtons/>}
          sort={{ field: 'id', order: 'DESC' }}
          pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100, 200, 500]}></Pagination>} empty={<EmptyList title={'Результаты отсутствуют'}/>}>
        <Datagrid>
            <IdShowButtonField label={'Id'}/>
            <TextField source="request.customId" label={'ID внутренний'}/>
            <TextField source="debtor" label={'Должник'}/>
            <TextField source="caseNumberFull" label={'Исполнительное производство'}/>
            <TextField source="caseNumber" label={'Номер ИП'}/>
            <TextField source="dateIp" label={'Дата ИП'}/>
            <TextField source="requisites" label={'Реквизиты исполнительного документа'}/>
            <TextField source="kindId" label={'Вид ИД'}/>
            <TextField source="dateId" label={'Дата ИД'}/>
            <TextField source="numberId" label={'Номер ИД'}/>
            <TextField source="endDate" label={'Дата окончания или прекращения ИП'}/>
            <TextField source="reason" label={'Причина окончания или прекращения ИП'}/>
            <TextField source="subjectAndAmount"
                       label={'Предмет исполнения, сумма непогашенной задолженности'}/>
            <TextField source="amount"
                       label={'Cумма непогашенной задолженности'}/>
            <TextField source="department" label={'Отдел судебных приставов'}/>
            <TextField source="bailiff" label={'Судебный пристав-исполнитель'}/>
            <DateField source={'request.completedAt'} label={'Проверен'} showTime/>
        </Datagrid>
    </List>
);

const TitleShow = ({ record }: any) => {
    return (
        <span>
      Результат № {record.id}
    </span>
    );
};
const SanitizedGrid = ({ ...props }) => {
    console.log("props.children", props.children)
    const newProps = {
        record: (props as any).record,
        resource: (props as any).resource,
        basePath: (props as any).basePath,
    };
    return (
        <Grid item={props.item} md={props.md} container={props.container}>
            {Children.map(props.children, field =>
                field && isValidElement(field) ? (
                    ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, { ...props, ...(field.props as any) }) :
                        <div
                            key={(field.props as any).source}
                            className={classnames(
                                `ra-field ra-field-${(field.props as any).source}`,
                                (field.props as any).className
                            )}
                        >
                            {(field.props as any).addLabel ? (
                                <Labeled
                                    record={props.record}
                                    resource={props.resource}
                                    basePath={props.basePath}
                                    label={(field.props as any).label}
                                    source={(field.props as any).source}
                                    disabled={false}
                                >
                                    {field}
                                </Labeled>
                            ) : typeof field.type === 'string' ? (
                                field
                            ) : (
                                cloneElement(field, newProps)
                            )}
                        </div>
                ) : null
            )}
        </Grid>
    );
};

export const ResultShow = (props) => (
    <Show {...props} title={<TitleShow/>}>
        <SimpleShowLayout>
            <TextField source="id" label={'ID'}/>
            <TextField source="request.customId" label={'ID внутренний'}/>
            <TextField source="request.caseNumber" label={'Номер ИП'}/>
            <TextField source="debtor" label={'Должник'}/>
            <TextField source="caseNumberFull" label={'Исполнительное производство'}/>
            <TextField source="caseNumber" label={'Номер ИП'}/>
            <TextField source="dateIp" label={'Дата ИП'}/>
            <TextField source="requisites" label={'Реквизиты исполнительного документа'}/>
            <TextField source="kindId" label={'Вид ИД'}/>
            <TextField source="dateId" label={'Дата ИД'}/>
            <TextField source="numberId" label={'Номер ИД'}/>
            <TextField source="endDate" label={'Дата окончания или прекращения ИП'}/>
            <TextField source="reason" label={'Причина окончания или прекращения ИП'}/>
            <TextField source="subjectAndAmount"
                       label={'Предмет исполнения, сумма непогашенной задолженности'}/>
            <TextField source="department" label={'Отдел судебных приставов'}/>
            <TextField source="bailiff" label={'Судебный пристав-исполнитель'}/>
            <DateField source={'request.completedAt'} label={'Проверен'} showTime/>
        </SimpleShowLayout>

    </Show>
);

