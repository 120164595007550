import React from 'react';
import PropTypes from "prop-types";
import { List, ListItemText,ListItem  } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
const styles = makeStyles(
  theme => ({
  root: {
    color: theme.palette.error.main,
  }})
);


const FsspFormErrorsField = ({ record = {}, source }) => {
  const classes = styles();
return   record[source] && record[source].length > 0
    ?
    <List subheader={<li/>}>{record[source].map((error) => <ListItem>
      <ListItemText className={classes.root} primary={error.name}></ListItemText>
    </ListItem>)}</List>
    : (record['browserSession'] && record['browserSession']['status'] === 'error') ?
  <List subheader={<li/>}>
    <ListItem>
      <ListItemText className={classes.root} primary={record['browserSession'] && record['browserSession']['error'] ? (record['browserSession']['error']['message'] ? record['browserSession']['error']['message'] :
          JSON.stringify(record['browserSession']['error'])) : ''}></ListItemText>
    </ListItem>
  </List> : <div></div>;
}

FsspFormErrorsField.defaultProps = {
  addLabel: true,
};
FsspFormErrorsField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
}
export default FsspFormErrorsField;
