import { useRefresh } from "ra-core";
import React, { Fragment } from 'react';
import {
  Show,
  List,
  Datagrid,
  Filter,
  DateField,
  TextField,
  TextInput,
  TabbedShowLayout,
  Tab,
  BulkDeleteButton,
  TopToolbar,
  DeleteButton,
  SelectInput,
} from 'react-admin';
import { useRecursiveTimeout } from "../../components/recursiveTimeout";
import PauseButton from "./components/PauseButton";
//import ExportNameField from "./components/ExportNameField";
import FsspRequestTypeField from "../../components/FsspRequestTypeField";
import EmptyList from "../../components/EmptyList";
import ExportStatusField, { ExportStatusList } from "../../components/ExportStatusField";
import DateRangeFilterInput from "../../components/DateRangeFilterInput";
import IdShowButtonField from "../../components/IdShowButtonField";
import JsonErrorsField from "../../components/JsonErrorsField";

const ExportFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name||$contL" label={'Название'} alwaysOn variant={'outlined'}/>
    <DateRangeFilterInput source="createdAt||$between" label="Дата создания" alwaysOn/>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={ExportStatusList} alwaysOn/>
  </Filter>
);

const ListActions = () => (
  <TopToolbar>
      <ExportFilter context="button" />
  </TopToolbar>
);

const ExportеBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton {...props}/>
  </Fragment>
);

export const ExportList = (props) => {
  const refresh = useRefresh()
  useRecursiveTimeout(() => refresh(), 5000);

  return (
      <List {...props} title="Экспорты"
            filters={<ExportFilter/>} 
            actions={<ListActions/>}
            sort={{ field: 'id', order: 'DESC' }}
            bulkActionButtons={<ExportеBulkActionButtons/>}
            empty={<EmptyList title={'Экспорты отсутсвуют'}/>}>
        <Datagrid>
          <IdShowButtonField source="id"/>
          <TextField source={'name'} label={'Название'}/>
          <ExportStatusField source="status" label={'Статус'} />
          <TextField source="total" label={'Всего заявок'}/>
          <TextField source="hasResults" label={'Заявок с ответами'}/>
          <TextField source="totalResults" label={'Всего результатов'}/>
          <TextField source="progress" label={'Прогресс'}/>
          <PauseButton showDelete/>
          <DateField source={'createdAt'} label={'Создан'} showTime/>
        </Datagrid>
      </List>
  );
}
const TitleShow = ({ record } : any) => {
  return (
    <span>
      Экспорт заявок № {record.id}
    </span>
  );
};

const ExportShowActions = (props) => (
  <TopToolbar>
    <PauseButton {...props} record={props.data}/>
    <DeleteButton label={''}  record={props.data} mutationMode="pessimistic" redirect={false}/>
  </TopToolbar>
);

export const ExportShow = (props) =>(
  <Show {...props} title={<TitleShow/>} actions={<ExportShowActions/>}>
    <TabbedShowLayout>
      <Tab label="Данные экспорта">
        <TextField source="id" />
        <FsspRequestTypeField source="type" label={'Тип'}/>
        <TextField source="fileName" label={'Файл'}/>
        <ExportStatusField source="status" label={'Статус'} />
        <TextField source="total" label={'Всего'}/>
        <TextField source="importedRows" label={'Экспортировано'}/>
        <TextField source="errorRows" label={'Ошибок'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Ошибки экспорта" path="errors">
        <Tab label="Ошибки экспорта" path="errors">
          <JsonErrorsField source={'error'} label={'Ошибки'} record={props.data}></JsonErrorsField>
        </Tab>
      </Tab>
    </TabbedShowLayout>

  </Show>
);

