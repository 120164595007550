import React, {    cloneElement} from 'react'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';
import { CreateButton, Datagrid } from 'react-admin'
import RelatedEmptyList from "./RelatedEmptyList";

const useStyles = makeStyles(
  theme => ({
    root: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-start',
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
      minHeight: theme.spacing(3),
      [theme.breakpoints.up('xs')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
      [theme.breakpoints.down('sm')]: {
        paddingRight: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
      },
    },
  }),
  { name: 'RaTopToolbar' }
);


const RelatedList = props => {
  const {record, createButtonTo, actions, createButtonLabel, total, hasCreate = true, loaded, loading, empty, emptyTitle, emptyDescription, emptyButtonText, title} = props
  const classes = useStyles(props);
    console.log("PPPPP", props)
  const shouldRenderEmptyPage =
    loaded &&
    !loading &&
    !total;
    if(shouldRenderEmptyPage){
      return empty ? cloneElement(empty, props) : <RelatedEmptyList {...props} title={emptyTitle} desription={emptyDescription} buttonText={emptyButtonText || createButtonLabel} to={typeof createButtonTo === 'function' ?  createButtonTo(record) : createButtonTo} />
    }
    console.log("TYPEOF", typeof createButtonTo)
  return (
<>
  {title && (<Typography variant="h6" component="h6">
    {title}
  </Typography>)}
  <Toolbar className={classes.root}>
    {actions ?   cloneElement(actions, {
      record
    }) : hasCreate ? <CreateButton to={typeof createButtonTo === 'function' ?  createButtonTo(props) : createButtonTo} label={createButtonLabel} /> : null}
  </Toolbar>

  <Datagrid { ...props} />
</>
)}
export default RelatedList
