import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";
export const AuthProvider = {
  login: ({ username, password }: any) => {
    const request = new Request(`${process.env.REACT_APP_API_URL || ''}/api/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ login: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    })
    return fetch(request)
      .then(async response => {
        if (response.status < 200 || response.status >= 300) {
          let message;
          try {
            message = await response.text();
            message = JSON.parse(message);
            message = message.errors;
          } catch (e) {
            // not json, no big deal
          }
          return Promise.reject({message, status: response.status});
        }
        return response.json()
      })
      .then(({ accessToken, codeConfirmed }) => {
        //localStorage.setItem('token', accessToken)
        Cookies.set('auth-token', accessToken)
        //localStorage.setItem('codeConfirmed', codeConfirmed)
      })
  },
  logout: () => {
    Cookies.remove('auth-token')
    //localStorage.removeItem('token')
    return Promise.resolve()
  },
  checkAuth: (route, route1) => {

    if (window.location.pathname === '/singup') {
      return Promise.resolve()
    }
    const accessToken = Cookies.get('auth-token');//localStorage.getItem('token');//
    if (!accessToken) {
      return Promise.reject()//{ redirectTo: '/login' })
    } else {
      const {role,id} = jwt_decode(accessToken) as any;
      //const codeConfirmed = localStorage.getItem('codeConfirmed');//Cookies.get('codeConfirmed');//
      if (role.length <= 1) {
        console.log(`redirectTo: /user/${id}`)
        return Promise.resolve({redirectTo: `/user/${id}`})
      }
    }
    return Promise.resolve()
  },
  checkError: (error: any) => {
    const status = error.status
    if (status === 401 || status === 403) {
      Cookies.remove('auth-token')
      //localStorage.removeItem('token')
      return Promise.reject(error)
    }
    return Promise.resolve()
  },
  getPermissions: () => {
    try {
      const accessToken = Cookies.get('auth-token')//localStorage.getItem('token');//
      const {role} = jwt_decode(accessToken) as any;
      //console.log("ROLE", role)
      return Promise.resolve(role)
    }catch (e){
      return Promise.reject();
    }
  },
}
