import React from 'react';
import { List, Datagrid, Show, SimpleShowLayout, SelectInput, Filter, TextInput, FunctionField, DateField, TextField } from 'react-admin';
import VideoLinkField from "../../components/VideoLinkField";
import StatusField from "../../components/RequestStatusField";
import ListActions from "../../components/ListActions";
import EmptyList from "../../components/EmptyList";
import ScreenshotLinkField from '../../components/ScreenshotLinkField';
import BrowserStatusField, { BrowserStatusList } from '../../components/BrowserStatusField';
const BrowserSessionFilter = (props) => (
  <Filter {...props}>
    <SelectInput source="status" label={'Статус'} variant={'outlined'} emptyText={'Не применять'} allowEmpty={true} choices={BrowserStatusList} />
    <TextInput source="id" label={'id'} variant={'outlined'}/>
  </Filter>
);
export const BrowserSessionList = (props) => (
  <List {...props} title="Сессии"    filters={<BrowserSessionFilter />} actions={<ListActions showCreate={false}/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false}   empty={<EmptyList title={'Пока сессий не было'} description={'Для того чтобы начать работать импортируйте или создайте заявку'} showButton={false}/>}>
    <Datagrid    rowClick="show">
      <TextField source="id" />
      <BrowserStatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source="video" label={'Видео'} />
    </Datagrid>
  </List>
);

const Title = ({ record } : any) => {
  return (
    <span>
      Сессия № {record.id}
    </span>
  );
};

export const BrowserSessionShow = (props) => (
  <Show {...props} title={<Title />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <BrowserStatusField source="status" label={'Статус'} />
      <DateField source={'createdAt'} label={'Создан'} showTime/>
      <DateField source={'startAt'} label={'Старт'} showTime/>
      <DateField source={'finishAt'} label={'Финиш'} showTime/>
      <FunctionField label="Прогресс" render={record => `${record.currentStep ? Math.ceil((record.currentStep / record.totalSteps) * 100) + '%' : ''}`} />
      <TextField source={'currentStepName'} label={'Шаг'}/>
      <ScreenshotLinkField source={"screenshot"} label={'Скриншот'} addLabel={true}/>
      <VideoLinkField source="video" label={'Видео'} />
      <TextField source={'error'} label={'Ошибка'}/>
      <TextField source={'sessionId'} label={'ID сессии'}/>
    </SimpleShowLayout>
  </Show>
);
